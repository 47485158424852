@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/gridle";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
.product-deal {
	background: none;
	margin: 0;
	width: 100%;
	height: 100%;

	@include gridle_state(md) {
		margin: 0;
	}

	&__status {
		@extend %text-bold;
		font-size: 2.4rem;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 100%;
		color: $color-error;
		text-transform: uppercase;
	}

	&__price-type {
		flex: 1 1 0%;
		justify-content: center;
		height: 100%;
		width: 100%;
		display: flex;
		align-items: center;

		.save-up-to {
			&__value {
				@extend %font-bold;
				letter-spacing: 0.1rem;
				font-size: 2rem;

				@include gridle_state(md) {
					font-size: 2.4rem;
				}
			}
		}

		.from {
			.amount {
				&__prefix {
					font-size: 1.4rem;
				}

				&__value {
					font-size: 2rem;
					line-height: initial;
				}

				&__suffix {
					font-size: 1.4rem;
				}

				@include gridle_state(md) {
					&__prefix {
						font-size: 1.4rem;
					}

					&__value {
						font-size: 2.4rem;
					}

					&__suffix {
						font-size: 1.4rem;
					}
				}
			}

			&__public-price {
				.amount {
					font-size: 1.3rem;

					&__prefix {
						font-style: italic;
						text-align: right;
					}

					&__value {
						text-decoration: line-through;
						font-size: 1.5rem;
					}
				}
			}
		}
	}

	&__exclusive {
		@extend %text-bold;
		font-size: 2.4rem;
		letter-spacing: 0.1rem;
		text-transform: uppercase;

		@include gridle_state(md) {
			font-size: 2.8rem;
		}
	}
}
