@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/gridle";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
.product-destination-title {
	text-align: left;
	font-size: 2.5rem;
	line-height: 1.2;

	@include gridle_state(md) {
		letter-spacing: 0;
	}

	&__region {
		@extend %font-bold;
		letter-spacing: 0;
		text-transform: uppercase;
	}

	&__resort {
		letter-spacing: 0;
		text-transform: none;
	}
}
